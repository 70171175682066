import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaUser } from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { ConfirmPopup } from "primereact/confirmpopup";
import { countries, userRoles } from "../../types/users.types";
import { Avatar } from "primereact/avatar";
import { Tag } from "primereact/tag";
import defaultAvatar from "../../assets/images/default-avatar.jpg";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

function ListAllUsers() {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    country: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    role: { value: null, matchMode: FilterMatchMode.EQUALS },
    isActive: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get("/user/list", {
        params: {
          role: ["CLIENT", "REPRESENTOR"],
          limit: 9999999,
        },
      })
      .then((res) => {
        setUsers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{countries[rowData.country]}</span>
      </div>
    );
  };

  const mobileBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.mobile}</span>
      </div>
    );
  };

  const emailBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{rowData.email}</span>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            className="p-inputtext-sm"
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const roleRowFilterTemplate = (options) => {
    return (
      <Dropdown
        options={[
          {
            label: "Client",
            value: "CLIENT",
          },
          {
            label: "Representor",
            value: "REPRESENTOR",
          },
        ]}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Role"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const countryRowFilterTemplate = (options) => {
    return (
      <Dropdown
        options={[
          {
            label: "Egypt",
            value: "EG",
          },
          {
            label: "Saudi Arabia",
            value: "SA",
          },
        ]}
        optionLabel="label"
        optionValue="value"
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Select Country"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const header = renderHeader();

  return (
    <div>
      <ConfirmPopup />
      <div className="flex justify-between items-center gap-2 w-full">
        <div className="">
          <div className="flex items-center gap-2">
            <FaUser size={20} />
            <span className="text-3xl font-bold">Users</span>
          </div>
          <small className="text-xs text-gray-400">List of all users</small>
        </div>
        <div className="flex md:justify-end">
          <Button
            label="Add User"
            icon="pi pi-plus"
            className="p-button-text p-button-sm mb-5"
            onClick={() => navigate("/users/add")}
          />
        </div>
      </div>

      <>
        <DataTable
          value={users}
          paginator
          size="small"
          loading={loading}
          rows={10}
          dataKey="_id"
          filters={filters}
          filterDisplay="row"
          globalFilterFields={["name", "country", "email", "isActive", "role"]}
          header={header}
          emptyMessage="No users found."
          rowHover
          onRowClick={(rowData) => navigate(`/users/${rowData?.data?._id}`)}
          style={{
            fontSize: "0.8rem",
          }}
        >
          <Column
            field="name"
            header="Name"
            filter
            showFilterMenu={false}
            filterPlaceholder="Search by name"
            body={(rowData) => (
              <div className="flex gap-2 items-center">
                <Avatar
                  image={rowData.avatar ? rowData.avatar : defaultAvatar}
                  size="large"
                  shape="circle"
                />
                {rowData.name}{" "}
                <Tag
                  className="p-tag-sm"
                  severity={rowData.isActive ? "success" : "warning"}
                >
                  {rowData.isActive ? "Active" : "Inactive"}
                </Tag>
              </div>
            )}
          />
          <Column
            header="Mobile"
            filterField="mobile"
            filter
            filterElement={
              <InputText
                className="p-inputtext-sm"
                type="text"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    mobile: {
                      value: e.target.value,
                      matchMode: FilterMatchMode.CONTAINS,
                    },
                  });
                }}
                placeholder="Search"
              />
            }
            body={mobileBodyTemplate}
            showFilterMenu={false}
          />
          <Column
            header="Country"
            filterField="country"
            filter
            filterElement={countryRowFilterTemplate}
            body={countryBodyTemplate}
            showFilterMenu={false}
          />
          <Column
            header="Email"
            filterField="email"
            filterMatchMode={FilterMatchMode.CONTAINS}
            showFilterMenu={false}
            filter
            filterElement={
              <InputText
                className="p-inputtext-sm"
                type="text"
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    email: {
                      value: e.target.value,
                      matchMode: FilterMatchMode.CONTAINS,
                    },
                  });
                }}
                placeholder="Search by email"
              />
            }
            body={emailBodyTemplate}
          />
          <Column
            header="Role"
            field="role"
            filterField="role"
            filter
            showFilterMenu={false}
            filterElement={roleRowFilterTemplate}
            body={(rowData) => <Tag>{userRoles[rowData.role]}</Tag>}
          />
        </DataTable>
      </>
    </div>
  );
}

export default ListAllUsers;
